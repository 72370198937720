@use "../../styles/colors" as *;
@use "../../styles/mixins" as *;

.hero {
  position: relative;
  // height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 8.6rem;

  @include tablet {
    margin-top: 7.4rem;
  }

  &__overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: red;
    z-index: 1;
    background-image: linear-gradient(
      180deg,
      $darkBlue 0%,
      rgba($blue, 0.5) 100%
    );
    background-color: rgba(255, 255, 255, 0);
  }

  &__left {
    border-radius: 50px;
    border: solid 2px white;
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px,
      rgba(0, 0, 0, 0.23) 0px 6px 6px;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);

    left: 10px;
    cursor: pointer;
    user-select: none;
    z-index: 5;
  }

  &__right {
    border-radius: 50px;
    border: solid 2px white;
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px,
      rgba(0, 0, 0, 0.23) 0px 6px 6px;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);

    right: 10px;
    cursor: pointer;
    user-select: none;
    z-index: 5;
  }

  &__image {
    object-fit: cover;
    width: 100%;
    min-height: 25rem;
    max-height: 25rem;
    z-index: 0;

    @include tablet {
      height: 100%;
      min-height: 35rem;
      max-height: 35rem;
    }
  }

  &__mobile--section {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    position: absolute;
    z-index: 2;

    @include tablet {
      display: none;
    }
  }

  &__tablet--section {
    display: none;

    @include tablet {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      position: absolute;
      z-index: 2;
    }
  }

  &__header {
    width: 90%;
    color: white;
    font-size: 1.1rem;
    margin: 0;
    margin-bottom: 0.5rem;
    text-shadow: 1px 2px 4px rgba(0, 0, 0, 1);
    // border: solid 1px red;
    width: 20rem;

    @include tablet {
      font-size: 2rem;
      width: 75%;
    }
  }

  &__subheader {
    color: white;
    font-size: 0.8rem;
    font-weight: 400;
    width: 20rem;
    text-shadow: 1px 2px 4px rgba(0, 0, 0, 1);
    padding: 1rem;
    margin-bottom: 0.5rem;
    @include tablet {
      font-size: 1rem;
      width: 50%;
      margin-top: 0.5rem;
    }
  }

  &__learn {
    @include cta-button-styling;

    &--tablet {
      color: white;
      background-color: $blue;
      // border: solid 2px $darkBlue;
      font-weight: 700;
    }
  }

  &__buttons {
    width: 15rem;
    display: flex;
    justify-content: center;
  }

  &__join {
    width: 7rem;
    border: none;
    border-radius: 7px;
    height: 2rem;
    background-color: $darkBlue;
    color: white;
    font-weight: 700;

    &:hover {
      background-color: rgb(19, 40, 89);
    }
  }
}
