@use "../../styles/colors" as *;
@use "../../styles/mixins" as *;

.board {
  background-color: $blue;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  width: 100%;

  &__header {
    padding: 2rem;
    font-weight: 400;
    font-size: 1.5rem;
    text-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
      rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
  }
  &__members {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: row;
  }

  &__card {
    height: 15rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    margin: 1rem;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 15px 25px,
      rgba(0, 0, 0, 0.05) 0px 5px 10px;

    @include tablet {
      width: 17%;
      min-width: 12rem;
      max-width: 20rem;
      max-height: 16rem;
    }
  }

  &__box {
    // border: solid 1px red;
  }

  &__image {
    display: flex;
    border-radius: 75px;
    width: 8rem;
    height: 8rem;
    background-color: $darkBlue;
    margin-bottom: 0.5rem;
    object-fit: cover;
  }

  &__name {
    font-size: 1.2rem;
    width: 14rem;
    margin: 0;
    text-align: center;
    text-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px,
      rgba(17, 17, 26, 0.1) 0px 0px 8px;
  }

  &__title {
    margin: 0;
    font-size: 0.8rem;
  }

  ::-webkit-scrollbar {
    width: 100%;
  }
  ::-webkit-scrollbar-track {
    // background-color: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #d6dee1;
    border-radius: 20px;
    border: 6px solid transparent;
    background-clip: content-box;
  }
  ::-webkit-scrollbar-thumb:hover {
    background-color: white;
  }
}
