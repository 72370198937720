@use "../../styles/colors" as *;
@use "../../styles/mixins.scss" as *;

.map {
  width: 100%;
  height: 50vw;

  @include desktop {
    height: 520px;
    width: 70%;
    max-height: 2000px;
  }

  &__card {
    display: flex;
    flex-direction: column;
    text-align: left;
    width: 100%;

    @include tablet {
      width: 19rem;
    }
  }

  &__text {
    color: $blue;
    text-decoration: none;
    padding: 0.1rem 0;
    font-size: 0.6rem;

    @include tablet {
      padding: 0.2rem 0;
      font-size: 1.2rem;
    }

    &--bold {
      color: black;
      font-weight: 500;
    }
  }

  &__title {
    padding: 0rem 0 0.4rem;
    font-size: 0.8rem;
    @include tablet {
      padding: 0.2rem 0 1rem;
      font-size: 1.4rem;
    }
  }
}
