@use "../../styles/colors" as *;
@use "../../styles/mixins" as *;

.anti {
  margin-top: 8.6rem;

  height: 1000rem;
  display: flex;
  flex-direction: column;
  padding: 1rem 10%;
  align-items: center;
  background-color: $blue;
  @include tablet {
    margin-top: 7.4rem;
  }
  &__title {
    font-size: 2rem;
    color: white;
    padding: 0.5rem;
  }

  &__text {
    color: white;
    padding: 0.5rem;
  }
  &__list {
    width: 70%;
    text-align: left;
  }
}
