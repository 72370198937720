@use "../../styles/colors.scss" as *;
@use "../../styles/mixins.scss" as *;

.nav {
  box-sizing: border-box;
  position: fixed;
  top: 0;
  display: flex;
  width: 100vw;
  padding: 0 1rem;
  flex-direction: column;
  background-color: rgb(255, 255, 255, 0.97);
  backdrop-filter: blur(3px);
  border-bottom: 3px solid $blue;
  z-index: 10;
  @include tablet {
    padding: 0 2rem;
  }
  &__container {
    // box-sizing: border-box;
    display: flex;
    width: 100;
    justify-content: space-between;
    align-items: center;
  }

  &__logo {
    height: 100px;
    margin: 1rem 0.5rem;

    @include tablet {
      height: 80px;
    }
  }

  &__menu-icon {
    margin-left: auto;
    padding-right: 2rem;
  }

  &__mobile-wrapper {
    display: flex;
    justify-content: space-between;
    width: 60%;
    @include tablet {
      display: none;
    }
  }

  &__tablet-wrapper {
    display: none;

    @include tablet {
      width: 100%;
      display: flex;
      justify-content: center;
      justify-content: space-evenly;
      padding-right: 1.5rem;
    }
  }

  &__button-mobile {
    @include button-styling;
    height: 100%;
    width: 10rem;

    @include tablet {
      display: none;
    }
  }
  &__button-tablet {
    color: $blue;
    height: 100%;
    background-color: white;
    width: 10rem;
    height: 59px;
    border: 3px solid $blue;
    border-radius: 5px;
    margin-left: 2rem;
  }
}
