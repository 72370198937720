@use "../../styles/colors.scss" as *;
@use "../../styles/mixins.scss" as *;

.mobile-nav-list {
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  @include tablet {
    display: none;
  }

  &__item {
    width: 100%;
    border: solid 1px $blue;
    cursor: pointer;
    list-style: none;
    transition: 0s;
    // padding-bottom: 2px;
    border-bottom: 2px transparent solid;
    padding: 2rem;

    &:hover {
      background-color: rgb(240, 240, 240);
      color: white;
    }

    @include tablet {
      &:hover {
        padding-bottom: 2px;
        border-bottom: 2px $red solid;
      }
    }

    &:active {
      @include tablet {
        padding-top: 2px;
        padding-bottom: 2px;
        border-bottom: 2px $red solid;
      }
    }
  }
  &__link {
    box-sizing: border-box;
    width: 100%;
    color: $blue;
    text-decoration: none;
    font-weight: 500;
  }
}
