@use "../../styles/colors" as *;
@use "../../styles/mixins" as *;

.contact {
  box-sizing: border-box;
  width: 100%;
  padding: 2rem 1rem 1.5rem 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: "Roboto";
  background-color: rgb(241, 241, 241);

  &__title {
    color: $blue;
    font-weight: 400;
    padding-bottom: 1.5rem;
  }

  &__label {
    font-size: 0.8rem;

    &--error {
      color: red;
    }
  }

  &__input {
    width: 18rem;
    height: 1.5rem;
    border-radius: 4px;
    border: solid 1px $darkBlue;
    margin-bottom: 1rem;
    margin-left: 0.5rem;
    padding-left: 0.5rem;

    &--textarea {
      resize: none;
      height: 6rem;
      width: 18rem;
      font-family: "Roboto";
    }
  }

  &__button {
    @include cta-button-styling;
    margin-top: 1rem;
  }
}
