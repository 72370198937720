@use "../../styles/colors" as *;
@use "../../styles/mixins" as *;

.newsletter {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;

  @include tablet {
    flex-direction: row;
  }

  &__button {
    border: none;
    margin: 1rem;
    background-color: $darkBlue;
    color: white;
    height: 3rem;
    width: 10rem;
    border: solid 1px $blue;
    border-radius: 25px;
    font-weight: 700;
    cursor: pointer;

    &:hover {
      background-color: $blue;
    }
  }
}
