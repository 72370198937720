@use "../../styles/colors.scss" as *;
@use "../../styles/mixins.scss" as *;

.members {
  display: flex;
  flex-direction: column;
  @include desktop {
    align-items: center;
    // height: 40vw;
    max-height: 600px;
    width: 70%;
    margin: 0 auto 5rem;

    background-color: white;
  }

  &__container {
    display: flex;
    align-items: flex-end;
    width: 100%;
    @include desktop {
    }
  }

  &__category {
    // border: solid 1px black;
    // cursor: pointer;
    height: 4.6rem;
    color: $blue;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-bottom: none;

    @include tablet {
    }
    @include desktop {
      border: 2px solid $blue;
      height: 4rem;

      padding: 0 1rem;
    }

    // &:hover {
    //   background-color: $blue;
    //   color: white;
    // }

    &--active {
      height: 4.6rem;
      background-color: $darkBlue;

      color: white;
    }
  }

  &__card {
    cursor: pointer;
    border: solid 1px grey;
    padding: 1rem 0;
    background-color: white;
    border-left: none;
    border-right: none;

    @include tablet {
    }
    @include desktop {
      border: solid 1px grey;
    }
    &:hover {
      background-color: $darkBlue;
      color: white;
    }
  }

  &__desktop-wrapper {
    display: flex;
    flex-wrap: wrap;
    @include desktop {
      margin-top: 2rem;
      height: calc(100% - 74px);
      max-height: 800px;
      width: 100%;
    }
  }

  &__mobile-list {
    // padding-top: 34.27rem;

    height: 300px;
    overflow-y: scroll;

    @include desktop {
      display: none;
    }
  }

  &__desktop-list {
    display: none;

    @include desktop {
      display: flex;
      flex-direction: column;
      height: 100%;
      width: 30%;
      overflow-y: scroll;
    }
  }

  &__category-container {
    display: flex;
    width: 100%;
  }

  &__desktop-search {
    display: none;
    @include desktop {
      @include search;
      border-bottom: none;

      display: flex;
      margin: 0;
      width: 35%;
    }
  }

  &__mobile-search {
    @include search;

    @include desktop {
      display: none;
    }
  }
}
