@use "variables" as *;
@use "colors" as *;
@use "typography" as *;

@mixin tablet {
  @media (min-width: $tablet-breakpoint) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: $desktop-breakpoint) {
    @content;
  }
}
@mixin button-styling {
  cursor: pointer;
  font-weight: 900;
  background-color: $blue;
  color: white;
  font-size: 1rem;
  border: none;
  width: 140px;
  height: 38px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;

  &:hover {
    background-color: $darkBlue;
  }
}

@mixin cta-button-styling {
  font-size: 0.7rem;
  font-weight: 700;
  border: none;
  color: white;
  background-color: $blue;
  height: 3rem;
  border-radius: 25px;
  width: 8rem;
  cursor: pointer;

  &:hover {
    background-color: $darkBlue;
    color: white;
  }
}

@mixin search {
  box-sizing: border-box;
  outline: none;
  margin: 0.5rem;
  background-color: white;
  width: 100%;
  justify-self: flex-end;
  align-self: flex-end;
  margin-right: 0;
  height: 3rem;
  color: black;
  padding-left: 1rem;
  font-size: 1.2rem;
  border: solid 1px $blue;
  margin-bottom: 0.5rem;

  &::placeholder {
    color: grey;
  }
  &:focus {
    border: solid 2px $darkBlue;
    // border-bottom: none;
  }
}
