@use "../../styles/colors.scss" as *;
@use "../../styles/mixins.scss" as *;

.nav-list {
  display: flex;
  // flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  margin-left: auto;

  @include tablet {
    width: 100%;
    max-width: 40rem;
    flex-direction: row;
    justify-content: space-between;
  }

  @include desktop {
    width: 45rem;
  }

  &__item {
    cursor: pointer;
    font-size: 1rem;
    font-weight: 700;
    list-style: none;
    transition: 0.5s;
  }

  &__link {
    color: $blue;
    text-decoration: none;
    padding: 0 0.5rem;

    &:hover {
      @include tablet {
        padding-top: 2px;
        padding-bottom: 2px;
        color: $darkBlue;
        border-bottom: 2px $red solid;
      }
    }

    &:active {
      padding-top: 2px;
      padding-bottom: 2px;
      border-bottom: 2px white solid;
    }
  }
}
