@use "../../styles/colors" as *;
@use "../../styles/mixins" as *;

.footer {
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: $darkBlue;
  color: white;
  padding: 2rem;

  &__section {
    margin: 1;
  }

  &__text {
    margin: 0;
    margin-bottom: 0.25rem;
    font-size: 1rem;
    text-align: center;
    font-weight: 400;
  }

  &__copyright {
    margin-bottom: 0.25rem;
    text-align: center;
    font-size: 0.75rem;
    @include tablet {
    }
    font-size: 0.8rem;
  }
}
